<template>
  <div class="order-common">
    <b-modal
      id="md-change-status"
      hide-footer
      title="Đổi trạng thái:"
      no-close-on-backdrop
      size="lg"
    >
      <div class="mt-4">
        <b-row>
          <b-col cols="6">
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Chọn trạng thái"
              label-class="font-weight-bold"
              class="mb-0"
            >
              <treeselect
                :options="optionStatus"
                :multiple="false"
                placeholder="Nhập trạng thái:"
                noResultsText="Không có kết quả"
                :match-keys="['label', 'custom']"
                v-model="statusParent"
                openDirection="bottom"
                :clearable="false"
                @select="onSelectStatus"
              >
                <label
                  slot="option-label"
                  slot-scope="{ node, labelClassName }"
                  :class="labelClassName"
                >
                  <span :title="node.label"> {{ node.label }}</span>
                </label>
              </treeselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          class="table-common"
          hover
          bordered
          :items="items"
          :fields="fields"
        >
          <template #cell(statusValidate)="data">
            <div
              v-if="!data.item.onLoading"
              class="d-table-row"
            >
              <div
                class="d-table-cell align-middle"
                style="width: 10%"
              >
                <i
                  v-b-tooltip.hover
                  :title="htmlGetStatusValidate(data.item, 'title')"
                  :class="htmlGetStatusValidate(data.item, 'class')"
                ></i>
              </div>
              <div
                class="d-table-cell align-middle pb-1 pl-2"
                style="width: 90%"
              >
                {{ data.item.statusValidateName }}
              </div>
            </div>
            <div v-else>
              <b-spinner
                small
                label="Small Spinner"
                variant="primary"
                class="mr-1"
              ></b-spinner>
              <span class="text-primary">Đang xử lí...</span>
            </div>
          </template>
          <template #cell(customerId)="data">
            <b class="text-">{{ data.item.customerName }}</b>
            <span class="cursor-pointer d-block mb-1">
              {{ data.item.phoneNumber }}
            </span>
          </template>
          <template #cell(status)="data">
            <b-form-group class="mb-0 d-flex align-items-center">
              <div class="d-table-row">
                <div
                  class="d-table-cell align-middle"
                  style="width: 40%"
                >
                  <label class="font-weight-bold pt-1">
                    {{ data.item.statusName }}
                  </label>
                </div>
                <div
                  class="d-table-cell align-middle"
                  style="width: 10%"
                >
                  <i
                    class="fa fa-arrow-right text-primary"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  class="d-table-cell align-middle pb-1"
                  style="width: 55%"
                >
                  <treeselect
                    :options="optionStatus"
                    :multiple="false"
                    placeholder="Chọn trạng thái"
                    noResultsText="Không có kết quả"
                    :match-keys="['label', 'custom']"
                    v-model="data.item.toStatus"
                    openDirection="bottom"
                    :clearable="false"
                    @select="onChangeStatus(data.item, $event)"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label"> {{ node.label }}</span>
                    </label>
                  </treeselect>
                </div>
              </div>
            </b-form-group>
          </template>
          <template #cell(actions)="row">
            <v-icon
              small
              class="text-danger"
              @click="onRemovedItem(row.item.id)"
              v-b-tooltip
              title="Xóa"
            >mdi-delete</v-icon>
          </template>
        </b-table>
        <vcl-table
          v-if="onLoadingList"
          :speed="5"
          :animate="true"
          :columns="10"
          class="mb-4"
        ></vcl-table>
      </div>
      <b-overlay
        :show="onLoadingList || isBusy"
        rounded
        opacity="0.6"
        class="d-inline-block mr-3"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner
              variant="light"
              small
              type="grow"
            ></b-spinner>
          </div>
        </template>
        <b-button
          v-if='saveStatusState !== "DONE"'
          :disabled="onLoadingList || !items.length"
          variant="primary"
          size="sm"
          @click="onSaveOrderStatus"
        >
          <strong>Cập nhật</strong>
        </b-button>
      </b-overlay>

      <b-button
        style="width: 80px"
        variant="secondary"
        size="sm"
        @click="hideModalChangeStatus()"
      >
        <strong>Hủy</strong>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import apiCommonMixin from '@/view/mixins/api-common.mixins.js';
import linkMixin from '@/view/mixins/link.mixins.js';
import { validationMixin } from 'vuelidate';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { mapGetters } from 'vuex';
import { SET_VALUATION_REQUEST_SELECTED } from '@/core/services/store/modules/trade-in/valuation-request.module.js';
import { VALUATION_REQUEST_STATUS } from '@/utils/constants';
import { removeAccents, cloneDeep, makeToastFaile } from '@/utils/common';
import { VclTable } from 'vue-content-loading';
import { EventBus } from '@/core/services/event-bus';
import ENUM from '@/utils/enum';
import Bluebird from 'bluebird';
import { checkGroupValuationRequestStatus } from '../../../utils/common';

const STATUS_VALIDATE = {
  INVALID: 0,
  VALID: 1,
  REQUIRED: 2,
};
const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  mixins: [validationMixin, apiCommonMixin, linkMixin],
  props: {
    userInfor: Object,
    param: Object,
    mode: String,
  },
  components: {
    Treeselect,
    VclTable,
  },
  data() {
    return {
      fields: [],
      defaultFields: [
        {
          key: 'id',
          label: 'ID',
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          formatter: (value) => {
            return `#${value}`;
          },
          tdClass: 'align-middle',
        },
        {
          key: 'customerId',
          label: 'Thông tin khách hàng',
          thStyle: {
            ...thStyleCommon,
            width: '35%',
          },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'status',
          label: 'Đổi trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '50%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'statusValidate',
          label: 'Kiểm tra',
          thStyle: {
            ...thStyleCommon,
            width: '28%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
        },
      ],
      items: [],
      itemsTerm: [],
      optionStatus: [],
      onLoadingList: false,
      isBusy: false,
      statusParent: null,
      changingStatusStores: [],
      isSelectedStore: null,
      saveStatusState: 'NEW',
    };
  },
  watch: {
    getValuationRequestStatus: {
      handler(value) {
        this.itemsTerm = value;
      },
      deep: true,
    },
    $props: {
      handler() {
        switch (this.mode) {
          case 'changeStatus':
            this.selectedStatus = null;
            break;
          default:
            break;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initConstants();
  },
  mounted() {
    EventBus.$on('popup-update-status', this.popupUpdateStatus);
  },
  beforeDestroy() {
    EventBus.$off('popup-update-status', this.popupUpdateStatus);
  },
  computed: {
    ...mapGetters(['getValuationRequestStatus']),
  },
  methods: {
    initConstants() {
      this.fields = this.defaultFields;
      this.optionStatus = VALUATION_REQUEST_STATUS.reduce((result, element) => {
        result.push({
          id: element.value ? Number(element.value) : -1,
          label: element.text,
          custom: removeAccents(element.text),
          customLabel: element.text,
        });
        return result;
      }, []);
    },
    htmlIsShown(value, name) {
      let check = false;
      switch (name) {
        case 'cancel-status': {
          const status = [
            ENUM.ORDER_STATUS.UN_SUCCESS,
            ENUM.ORDER_STATUS.SYSTEM_CANCEL,
            ENUM.ORDER_STATUS.CR_CANCEL,
          ];
          if (status.includes(value)) {
            check = true;
          }
          break;
        }
      }
      return check;
    },
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class' ? 'fas fa-check-circle text-success' : 'Hợp lệ';

          break;
        }
        case STATUS_VALIDATE.REQUIRED: {
          name =
            type === 'class'
              ? 'fas fa-check-circle text-warning'
              : 'Chưa chọn lí do';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
      }
      return name;
    },
    onSelectStatus(node) {
      this.isSelectedStore = false;
      const statusChanged = node.id;

      this.items = this.items.map((item) => {
        const [statusValidate, statusValidateName] = this.validationStoreStatus(
          item.status,
          statusChanged,
        );
        return {
          ...item,
          toStatus: node.id,
          toStatusName: node.label,
          statusValidate: statusValidate,
          statusValidateName: statusValidateName,
        };
      });
    },
    onChangeStatus(storeSelected, event) {
      const newListStores = [...this.items];

      this.items = newListStores.map((currentItem) => {
        if (currentItem.id === storeSelected.id) {
          const currentStatus = currentItem.status;
          const newStatus = event.id;
          const [statusValidate, statusValidateName] =
            this.validationStoreStatus(currentStatus, newStatus);
          return {
            ...currentItem,
            toStatus: event.id,
            toStatusName: event.label,
            statusValidate: statusValidate,
            statusValidateName: statusValidateName,
          };
        }
        return currentItem;
      });
    },
    hideModalChangeStatus() {
      this.$bvModal.hide('md-change-status');
      this.saveStatusState = 'NEW';
      this.changingStatusStores = [];
      this.items = [];
      this.isSelectedStore = null;
    },
    validationStoreStatus(statusChild, statusChanged) {
      let statusValidate = STATUS_VALIDATE.VALID;
      let statusValidateName = 'Hợp lệ';

      if (statusChanged === -1) {
        statusValidate = STATUS_VALIDATE.INVALID;
        statusValidateName = 'Chưa chọn';
        return [statusValidate, statusValidateName];
      }

      if (statusChild === statusChanged) {
        statusValidate = STATUS_VALIDATE.INVALID;
        statusValidateName = 'Trạng thái trùng lặp';
        return [statusValidate, statusValidateName];
      }

      try {
        const changedGroup =
          checkGroupValuationRequestStatus().getByGroup(statusChanged);
        const sourceGroup =
          checkGroupValuationRequestStatus().getByGroup(statusChild);
        const checkStatus = checkGroupValuationRequestStatus().valid(
          sourceGroup,
          changedGroup,
        );

        if (!checkStatus) {
          statusValidate = STATUS_VALIDATE.INVALID;
          statusValidateName = 'Không hợp lệ';
        }
      } catch (error) {
        makeToastFaile(error.message);
        statusValidate = STATUS_VALIDATE.INVALID;
        statusValidateName = error.message;
      }

      return [statusValidate, statusValidateName];
    },
    onRemovedItem(itemRemoveId) {
      const findIndexBy = (items, itemRemoveId) => {
        return items.findIndex((item) => item.id === itemRemoveId);
      };

      const index = findIndexBy(this.items, itemRemoveId);
      if (index > -1) {
        this.items.splice(index, 1);
        this.$store.dispatch(SET_VALUATION_REQUEST_SELECTED, this.items);
        EventBus.$emit('re-seletect-order', this.items);
      }
    },
    onSaveOrderStatus: async function () {
      const clonedItems = cloneDeep(this.items);
      const invalids = clonedItems.filter((item) => !item.statusValidate);
      if (invalids.length) {
        return makeToastFaile(
          `Đơn hàng không hợp lệ ${invalids.map((item) => item.id).join()}`,
        );
      }

      await Bluebird.each(clonedItems, async (clonedItem) => {
        if (this.isBusy) return;

        const index = this.items.findIndex((e) => e.id === clonedItem.id);
        this.items[index].statusValidate = STATUS_VALIDATE.LOADING;

        this.isBusy = true;
        try {
          const param = [
            {
              id: clonedItem.id,
              status: clonedItem.toStatus,
              note: clonedItem.note || '',
            },
          ];
          await ApiService.patch(`valuation-requests/status`, param);
          this.items[index].statusValidate = STATUS_VALIDATE.VALID;
          this.items[index].statusValidateName = 'Thành công';
        } catch (err) {
          const message = err.response ? err.response.data.message : 'Lỗi mạng';

          this.items[index].statusValidate = STATUS_VALIDATE.INVALID;
          this.items[index].statusValidateName =
            message || 'Lỗi không xác định';
        } finally {
          this.isBusy = false;
        }
      });

      this.saveStatusState = 'DONE';
      this.$store.dispatch(SET_VALUATION_REQUEST_SELECTED, []);
      this.$emit('refresh');
    },
    popupUpdateStatus() {
      if (!this.itemsTerm.length) {
        this.$store.dispatch(SET_VALUATION_REQUEST_SELECTED, []);
        makeToastFaile('Vui lòng chọn lại');
        return;
      }

      this.$bvModal.show('md-change-status');

      this.statusParent = null;
      this.items = this.itemsTerm;
    },
  },
};
</script>

<style scoped>
.table-common /deep/ .vue-treeselect__input-container {
  padding-top: 0px;
}
.table-common /deep/ .vue-treeselect__control {
  border-radius: none;
  border: none;
  height: 2.5rem;
}

.table-common /deep/ .vue-treeselect__input {
  font-size: 22px;
}
</style>
